import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import {
  faTwitter,
  faFacebookSquare,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";
import OptimizedImage from "hooks/OptimizedImage";
import Newyork from "assets/new-york.png";
import Bigben from "assets/big-ben.png";
import East from "assets/mid-east.png";

const SecondContact = ({ styleOne }) => {
  return (
    <>
      {styleOne == "1" ? (
        <div className="bg-midBlue py-24">
          <h3 className="font-secondary text-[35px] font-bold text-orange text-center pb-24">
            Find us here
          </h3>
          <div className="grid sm:grid-cols-3 gap-y-20 grid-cols-1 sm:w-9/12 mx-auto">
            <div className="flex flex-col justify-center items-center">
              <img src={Newyork} alt="" />
              <span className="text-[22px] font-secondary font-semibold text-white pt-6">
                United States
              </span>
              <p className="text-white font-opensans text-base text-center pt-4">
                9450 SW Gemini Dr #34540, Beaverton, <br /> OR 97008-7105 <br />
                Call : +1 985 239 0142
              </p>
            </div>
            <div className="flex flex-col justify-center items-center">
              <img src={East} alt="" />
              <span className="text-[22px] font-secondary font-semibold text-white pt-6">
                Middle East
              </span>
              <p className="text-white font-opensans text-base text-center pt-4">
                26 HaRokmim st. Azrieli Business Center, <br /> Building C, 7th
                floor, Holon 5885849, Israel <br /> Call : +972 (0)72 220 0700
              </p>
            </div>
            <div className="flex flex-col justify-center items-center">
              <img src={Bigben} alt="" />
              <span className="text-[22px] font-secondary font-semibold text-white pt-6">
                Europe
              </span>
              <p className="text-white font-opensans text-base text-center pt-4">
              124 City Road, London,
                <br />
                EC1V 2NX, United Kingdom
                <br /> Call: +44 (0)16 1509 6140
              </p>
            </div>
          </div> 
        </div>
      ) : (
        <div className="bg-midBlue">
          <div className="justify-center m-auto flex-col text-white pt-12 max-w-5xl">
            <div className="flex-col flex-wrap -mx-3 mb-6 text-center">
              <h2 className="text-3xl pb-4 font-secondary">You can find us</h2>
              <p className="font-opensans ">
                <a href="mailto:support@tomedes.com" target="_top">
                  <FontAwesomeIcon icon={faEnvelope} className="w-2/12 mr-3" />
                  support@tomedes.com
                </a>
              </p>
            </div>
            <div className="socialMedia flex gap-x-14 lg:gap-x-6 m-auto justify-center pb-8">
              <a
                href="https://www.facebook.com/pages/Tomedes/389883657742674"
                target="_blank"
                style="color:#fff;"
              >
                <FontAwesomeIcon icon={faFacebookSquare} className="mr-3" />
              </a>
              <a
                href="https://www.twitter.com/Tomedes"
                target="_blank"
                style="color:#fff;"
              >
                {/* <FontAwesomeIcon icon={faTwitter} className="mr-3" /> */}
                <svg
                  // className="social_media_icon_hover"
                  fill=""
                  width="40"
                  zoomAndPan="magnify"
                  viewBox="0 0 375 374.999991"
                  height="40"
                >
                  <defs>
                    &lt; d="M 8.917969 7.773438 L 367.417969 7.773438 L
                    367.417969 366.273438 L 8.917969 366.273438 Z M 8.917969
                    7.773438 " /&gt;{" "}
                  </defs>

                  <g transform="translate(90,80) ">
                    {" "}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 44 44"
                      version="1.1"
                      height="full"
                      width="full"
                    >
                      <path
                        d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z"
                        fill="#ffffff"
                      ></path>
                    </svg>
                  </g>
                </svg>
              </a>
              <a
                href="https://www.linkedin.com/company/tomedes.com"
                target="_blank"
                style="color:#fff;"
              >
                <FontAwesomeIcon icon={faLinkedin} className="mr-3" />
              </a>
            </div>
            <div className="grid grid-cols-1 lg:grid-cols-3 place-items-center place-content-center">
              <div className="flex flex-col items-center justify-center lg:my-0 text-center">
                <OptimizedImage
                  src="https://tomedes.gumlet.io/frontend/images/newyork-1.png"
                  alt=""
                  className="w-157 h-157"
                />

                <div className="my-4">
                  <h2 className="font-secondary text-24">United States</h2>
                  <p className="font-opensans">
                    Tomedes Ltd - USA
                    <br />
                    9450 SW Gemini Dr #34540,
                    <br />
                    Beaverton, OR 97008-7105
                    <br />
                    Call : +1 985 239 0142
                  </p>
                </div>
              </div>
              <div className="flex flex-col items-center justify-center lg:my-0 text-center">
                <OptimizedImage
                  src="https://tomedes.gumlet.io/frontend/images/euro.png"
                  alt=""
                  className="w-157 h-157"
                />
                <div className="my-4">
                  <h2 className="font-secondary text-24">Middle East</h2>
                  <p className="font-opensans">
                    26 HaRokmim st.
                    <br />
                    Azrieli Business Center,
                    <span className="lg:block">Building C, 7th floor,</span>
                    Holon 5885849, Israel
                    <br />
                    Call : +972 (0)72 220 0700
                  </p>
                </div>
              </div>
              <div className="flex flex-col items-center justify-center lg:my-0 text-center">
                <OptimizedImage
                  src="https://tomedes.gumlet.io/frontend/images/uk.png"
                  alt=""
                  className="w-157 h-157"
                />
                <div className="my-4">
                  <h2 className="font-secondary text-24">Europe</h2>
                  <p className="font-opensans">
                    7 Bell Yard,
                    <br />
                    London, WC2A <br /> 2 JR United Kingdom
                    <br /> Call: +44 (0)16 1509 6140
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SecondContact;
